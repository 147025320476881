import { isDefined } from "@clipboard-health/util-ts";
import { deprecatedDoNotUseLogError, logError, logEvent } from "@src/appV2/lib/analytics";
import { USER_EVENTS } from "@src/constants";
import { Agent } from "@src/lib/interface";

import { fetchWorkerProfileByBookWithMeId } from "../components/workWithFriends/api";
import { IFriendLinkAlertProps } from "../components/workWithFriends/interface";
import { getQualifications } from "../licenseManager/api";
import { updateLicenseAndQualificationPreference } from "../licenseManager/utils";
import { FriendLinkType } from "../store/workerFriends/model";

export const processSuccessNonClinicalDeepLink = async (
  userId: string,
  searchParams: URLSearchParams
): Promise<Agent | undefined> => {
  const LOG_CONTEXT = "processSuccessNonClinicalDeepLink";
  const qualificationFromSearchParams = searchParams.get("qualification");

  if (!isDefined(qualificationFromSearchParams) || qualificationFromSearchParams.trim() === "") {
    deprecatedDoNotUseLogError({
      message: `${LOG_CONTEXT} qualification is missing in NON_CLINICAL dynamic link.`,
    });
    return;
  }
  try {
    const { data } = await getQualifications(true);
    const qualification = data
      .filter((item) => item.attributes.name.toLowerCase() !== "nurse")
      .find(
        (item) => item.attributes.name.toLowerCase() === qualificationFromSearchParams.toLowerCase()
      );

    if (!isDefined(qualification)) {
      deprecatedDoNotUseLogError({
        message: `${LOG_CONTEXT} Invalid qualification ${qualificationFromSearchParams} in non clinical deep link`,
      });
      return;
    }
    const updatedAgent = await updateLicenseAndQualificationPreference(
      qualification.attributes.name,
      userId
    );
    return updatedAgent;
  } catch (error) {
    logError(
      `${LOG_CONTEXT} Error while updating license details for non clinical type userId: ${userId}`,
      {
        error: error,
      }
    );
    throw error;
  }
};

export const processBookWithMeDeepLink = async (
  searchParams: URLSearchParams
): Promise<IFriendLinkAlertProps | undefined> => {
  const LOG_CONTEXT = "processBookWithMeDeepLink";
  const bookWithMeId = searchParams.get("bookWithMeId") || "";
  if (!bookWithMeId) {
    deprecatedDoNotUseLogError({
      message: `${LOG_CONTEXT} bookWithMeId missing in search params.`,
    });
    return;
  }
  try {
    const workerFriendResponse = await fetchWorkerProfileByBookWithMeId(bookWithMeId);
    if (!workerFriendResponse) {
      deprecatedDoNotUseLogError({
        message: `${LOG_CONTEXT} not able to fetch worker profile info`,
      });
      return fetchErrorAlertResponse(bookWithMeId);
    }
    const { header, message, hasProfilePic, workerId, type, name, qualification } =
      workerFriendResponse;
    if (!type || !Object.values(FriendLinkType).includes(type)) {
      deprecatedDoNotUseLogError({
        message: `${LOG_CONTEXT} Invalid action type provided for friend link response: ${JSON.stringify(
          workerFriendResponse
        )}`,
      });
      return fetchErrorAlertResponse(bookWithMeId, FriendLinkType.INVALID_LINK);
    }
    if (
      !header ||
      !message ||
      (type === FriendLinkType.FRIEND_ALREADY_EXIST && (!name || !qualification)) ||
      (type === FriendLinkType.NEW_FRIEND && (!workerId || !name || !qualification))
    ) {
      deprecatedDoNotUseLogError({
        message: `${LOG_CONTEXT} Some params are missing from friend link response: ${JSON.stringify(
          workerFriendResponse
        )}`,
      });
      return fetchErrorAlertResponse(bookWithMeId, FriendLinkType.INVALID_LINK);
    } else if (type === FriendLinkType.INVALID_LINK) {
      logEvent(USER_EVENTS.FRIEND_LINK_DOES_NOT_EXIST, {
        bookWithMeId: bookWithMeId,
      });
    } else if (type === FriendLinkType.OWN_LINK) {
      logEvent(USER_EVENTS.OPENED_OWN_FRIEND_LINK, {
        bookWithMeId: bookWithMeId,
      });
    }
    return {
      type,
      hasProfilePic,
      workerId,
      header,
      message,
      bookWithMeId,
      name,
      qualification,
    };
  } catch (error) {
    deprecatedDoNotUseLogError({
      message: `${LOG_CONTEXT} Error while processing friend link: ${searchParams.toString()}. Error:- ${
        error?.message
      }`,
    });
  }
};

export const fetchErrorAlertResponse = (
  bookWithMeId: string,
  type?: FriendLinkType
): IFriendLinkAlertProps => {
  switch (type) {
    case FriendLinkType.INVALID_LINK:
      return {
        type: FriendLinkType.INVALID_LINK,
        header: "This friend request link does not exist",
        message: "Please ask your friend for another link",
        bookWithMeId,
      };
    default:
      return {
        type: FriendLinkType.DEFAULT,
        header: "Error occured",
        message: "Please try again later.",
        bookWithMeId,
      };
  }
};

export function isValidUrl(uri: string): boolean {
  try {
    new URL(uri);
    return true;
  } catch {
    return false;
  }
}
